
import {
  IssuePriority,
  PriorityTypes,
} from "@/store/modules/priority/priority.types";
import { Pagination } from "@/types/types";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const priorityX = namespace("Priority");

@Component({})
export default class PrioritySelect extends Vue {
  @Prop() public label?: string;
  @Prop() public filled?: boolean;
  @Prop() public outlined?: boolean;
  @Prop() public dense?: boolean;
  @Prop() public hideDetails?: boolean;
  @Prop() public rules?: unknown[];
  @Prop() value?: number;

  @priorityX.Action(PriorityTypes.LOAD_PRIORITIES)
  public getPriorities!: (pagination: Pagination) => void;

  @priorityX.State(PriorityTypes.PRIORITIES)
  public priorities!: IssuePriority[];

  @priorityX.State(PriorityTypes.LOADING_PRIORITIES_STATE)
  public loadingPriorities!: boolean;

  @Emit("input")
  save(id: string): string {
    return id;
  }

  mounted(): void {
    this.getPriorities({ page: 1, limit: 10 });
  }
}
